.sidebar-container {
  display: flex;
  position: relative;
  height: 100vh;
  width: 80px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 80px;
  background-color: #ffffff;
  height: 100%;
  padding: 10px 0;
  z-index: 10;
  overflow-y: auto; /* Add scrolling capability */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #105 transparent; /* For Firefox */
}

/* For Webkit browsers (Chrome, Safari, Edge) */
.sidebar::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #105; /* Set the thumb color */
  border-radius: 4px; /* Optional: Add rounded corners */
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent; /* Set the track color */
}

.sidebar-panel {
  position: absolute;
  left: 80px;
  width: 220px;
  background-color: #105;
  height: auto;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: slideIn 0.2s ease-out;
  z-index: 5;
  max-height: calc(
    100vh - 20px
  ); /* Prevent panel from extending beyond viewport */
  overflow-y: auto; /* Add scrolling if panel content is too tall */
}

.panel-header {
  padding: 15px;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  background-color: #105;
  z-index: 1;
  text-align: right; /* Align text to the right */
  padding-right: 20px; /* Add some padding on the right */
}

.panel-content {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.panel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
}

.panel-item:hover,
.panel-item.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.panel-item.active {
  position: relative;
}

.panel-item.active::after {
  content: "";
  position: absolute;
  bottom: 5px;
  width: 20px;
  height: 2px;
  background-color: #00c64b;
  border-radius: 1px;
}

.panel-item-icon {
  font-size: 45px;
  margin-bottom: 8px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-item-icon svg {
  width: 45px;
  height: 45px;
}

.panel-item-icon i {
  font-size: 45px;
}

.panel-item-label {
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar-panel {
    position: fixed;
    left: 60px;
    width: 180px;
  }

  .sidebar {
    width: 60px;
  }

  .panel-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 50px;
  }

  .sidebar-panel {
    left: 50px;
    width: 160px;
  }
}

.sidebar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sidebar-item:hover,
.sidebar-item.panel-open,
.sidebar-item.route-active {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-button,
.sidebar-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.sidebar-item-icon {
  font-size: 20px;
  color: #105;
  transition: color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-item-icon svg {
  width: 45px;
  height: 45px;
}

.sidebar-item-icon i {
  font-size: 45px;
}

.sidebar-item:hover .sidebar-item-icon,
.sidebar-item.panel-open .sidebar-item-icon,
.sidebar-item.route-active .sidebar-item-icon {
  color: #00c64b;
}

.sidebar-tooltip {
  position: absolute;
  left: 0;
  top: -35px;
  background-color: #105;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 20;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.has-children-indicator {
  position: absolute;
  right: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #00c64b;
  transition: transform 0.2s, background-color 0.2s;
}

.has-children-indicator.open {
  background-color: #ffffff;
}

/* Route active indicator */
.sidebar-item.route-active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background-color: #00c64b;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar-tooltip {
    left: 60px;
  }
}

@media (max-width: 480px) {
  .sidebar-item {
    height: 45px;
  }

  .sidebar-tooltip {
    left: 50px;
  }
}

/* Layout styles */
.layout-container {
  width: 100%;
  position: relative;
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 100;
  width: 80px; /* Match the width of your sidebar */
}

.content-wrapper {
  margin-left: 80px; /* Match the width of your sidebar */
  width: calc(100% - 80px);
  min-height: 100vh;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar-wrapper {
    width: 60px;
  }

  .content-wrapper {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}

@media (max-width: 480px) {
  .sidebar-wrapper {
    width: 50px;
  }

  .content-wrapper {
    margin-left: 50px;
    width: calc(100% - 50px);
  }
}
